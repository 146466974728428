import { defineStore } from 'pinia'
import * as pageApi from '@@/api/PageApi'
import { createSlug } from '@@/helpers/util/index.js'

// Clean up meta data for direct access.
function assignData({ data }) {
  const { meta, ...content } = data
  const domain = import.meta.env.VITE_SITE_DOMAIN
  const url = `${domain}${meta.meta.url}`
  const pageMeta = {
    title: meta.title || '',
    description: meta.meta.description || '',
    card: meta.meta.card || '',
    url: url || '',
    image: meta.meta.image || '',
    ogType: meta.meta.ogType || ''
  }


  return { meta: pageMeta, ...content }
}

export const usePagesStore = defineStore('pages', {
  state: () => ({
    pageContent: {},
    viewLoading: true,
    metaInfo: {
      title: 'Trusted Photocopier and Printer Supplier',
      titleTemplate: '%s - Copytech',
      description: 'Simple, reliable and affordable print solutions for business.',
      url: '',
      image: '',
      card: '',
      ogType: ''
    },
    content: {}
  }),


  actions: {
    async fetchPage(route) {
      const path = route.path === '/' ? 'home' : route.path
      const slug = createSlug(path)
      const body = await pageApi.getPage(slug)


      this.STORE_PAGE_DATA({ slug, body })
    },
    STORE_PAGE_DATA({ slug, body }) {
      const data = assignData(body)
      this.pageContent[slug] = data
    }
  },

  getters: {
    getMetaInfo: (state, { route }) => {
      const { path } = route
      const slug = createSlug(path)

      const page = state.pageContent[slug]
      let meta
      if (page) {
        meta = page.meta
      }

      return meta

    },
    getPageContent: (state, { route }) => {
      const { path } = route
      const slug = createSlug(path)

      const page = state.pageContent[slug]

      return page
    },
    // pageContent: state => {
    //   return state.pageContent
    // }
  }
})
// export default { state, actions, mutations, getters }
