import { defineStore } from 'pinia'
import * as product from '@@/api/ProductApi'
import * as ProductHelper from '@@/helpers/product'

// Clean up meta data for direct access.
function assignData(data) {
  const { meta, ...content } = data
  const domain = import.meta.env.VITE_SITE_DOMAIN
  const url = `${domain}${meta.meta.url}`
  const pageMeta = {
    title: meta.title || '',
    description: meta.meta.description || '',
    card: meta.meta.card || '',
    url: url || '',
    image: meta.meta.image || '',
    ogType: meta.meta.ogType || ''
  }


  return { meta: pageMeta, content: content }
}

export const useProductsStore = defineStore('products', {
  // initial state
  state: () => ({
    loadingMultifunctionalPrinters: true,
    loadingOfficePrinters: true,
    loadingProductionPrinters: true,
    loadingPlotterPrinters: true,
    loadingFeaturedPrinters: true,
    // Printer products
    multifunctionals: [],
    printOnly: [],
    production: [],
    plotter: [],
    all: [],
    // Scanner products
    allScanners: [],
    personalScanners: [],
    departmentalScanners: [],
    productionScanners: [],
    desktopWorkgroupScanners: [],
    featured: {},
    selected: {
      /* Holds data for currently viewed page */
    },
    productMeta: {
      title: '',
      description: '',
      url: '',
      image: '',
      card: '',
      ogType: ''
    }
  }),

  // Product actions
  actions: {
    toggleLoadingProducts() {
      this.SET_PRODUCT_LOADING()
    },

    // Load all active printers
    async getAllPrinters() {
      const data = await product.getAllPrinters()
      this.SET_ALL_PRODUCTS(data)
    },
    // Load all active multifunctional printers
    async getMultifunctionalPrinters() {
      const data = await product.getMultifunctionalPrinters()
      this.SET_MULTIFUNCTIONAL_PRODUCTS(data)
    },
    // Load all active office printers
    async getOfficePrinters() {
      const data = await product.getOfficePrinters()
      this.SET_OFFICE_PRODUCTS(data)
    },
    // Load all active production printers
    async getProductionPrinters() {
      const data = await product.getProductionPrinters()
      this.SET_PRODUCTION_PRODUCTS(data)
    },
    // Load all active plotter printers
    async getPlotterPrinters() {
      const data = await product.getPlotterPrinters()
      this.SET_PLOTTER_PRODUCTS(data)
    },

    // Load all active scanners
    async getAllScanners() {
      const data = await product.getAllScanners()
      this.allScanners = data
    },
    // Load all personal scanners
    async getPersonalScanners() {
      const data = await product.getPersonalScanners()
      this.personalScanners = data
    },
    // Load all departmental scanners
    async getDepartmentalScanners() {
      const data = await product.getDepartmentalScanners()
      this.departmentalScanners = data
    },
    // Load all production scanners
    async getProductionScanners() {
      const data = await product.getProductionScanners()
      this.productionScanners = data
    },
    // Load all desktop workgroup scanners
    async getDesktopWorkgroupScanners() {
      const data = await product.getDesktopWorkgroupScanners()
      this.desktopWorkgroupScanners = data
    },

    async FETCH_FEATURED_PRODUCTS() {
      // Only load featured printers if we don't ahve them already
      if (this.featured.length > 0) {
        return
      } else {
        const data = await product.getFeaturedPrinters()
        this.SET_FEATURED_PRODUCTS(data)
        this.SET_FEATURED_PRINTERS_LOADING(false)
      }
    },
    async FETCH_PRINTER(request) {
      const modelId = request.modelId


      let remoteType = []
      // Check type is valid first
      switch (request.type) {
        case 'multifunctionals':
        case 'print-only':
        case 'production':
        case 'light-production':
        case 'mid-production':
        case 'heavy-production':
        case 'plotter':
          break
        default:
          // console.log('boop', request.type, modelId, request)
          throw { code: 404 }
      }

      if (request.type === 'multifunctionals') {
        remoteType = ['multifunctional-printers']
      }

      if (request.type === 'print-only') {
        remoteType = ['office-printers']
      }
      if (request.type === 'plotter') {
        remoteType = ['plotter']
      }

      if (request.type === 'production') {
        remoteType = [
          'multifunctional-printers',
          'office-printers',
          'light-production-printers',
          'light-production',
          'mid-production-printers',
          'mid-production',
          'heavy-production-printers',
          'heavy-production',
          'plotter'
        ]
      }

      const productData = await product.getSingleProduct(modelId)
      // const slug = modelId --> unused

      // Test for valid product
      if (!productData) {
        throw { code: 404 };
      }

      if (!remoteType.includes(productData.type)) {
        throw { code: 404 };
      }

      if (!productData.copytech.isActive) {
        throw { code: 404 };
      }

      this.SET_SINGLE_PRODUCT(productData);
    

    },
    async FETCH_SCANNER(request) {
      const modelId = request.modelId
      // return new Promise((resolve, reject) => {
      let remoteType
      // Check type is valid first
      switch (request.type) {
        case 'production':
        case 'departmental':
        case 'desktop-workgroup':
        case 'personal':
          break
        default:

          // console.log('beep', request.type, modelId)
          throw { code: 404 }
      }

      // TODO: Check this over, this is just a hack tog et this working

      remoteType = [
        'production-scanners',
        'departmental-scanners',
        'desktop-workgroup-scanners',
        'personal-scanners',
      ]

      const productData = await product.getSingleScanner(modelId)
      // const slug = modelId --> unused

      // Test for valid product
      if (!productData) {
        throw { code: 404 };
      }

      if (!remoteType.includes(productData.type)) {
        throw { code: 404 };
      }

      if (!productData.copytech.isActive) {
        throw { code: 404 };
      }

      this.SET_SINGLE_PRODUCT(productData)

    },
    async FETCH_RELATED_PRINTERS(modelId) {
      const result = {
        modelId
      }
      return product.getSimiliarPrinters(modelId).then(data => {
        result.printers = data
        this.SET_RELATED_PRINTERS(result)
      })
    },
    SET_MULTIFUNCTIONAL_PRODUCTS(data) {
      this.multifunctionals = data
    },
    SET_OFFICE_PRODUCTS(data) {
      this.printOnly = data
    },
    SET_PRODUCTION_PRODUCTS(data) {
      this.production = data
    },
    SET_PLOTTER_PRODUCTS(data) {
      this.plotter = data
    },
    SET_SCANNER_PRODUCTS(data) {
      this.scanner = data
    },
    SET_SINGLE_PRODUCT(body) {
      const data = assignData({ ...body, meta: ProductHelper.getProductMeta(body) })
      this.selected = data
    },
    SET_RELATED_PRINTERS(data) {
      this.related[data.modelId] = data.printers
    },
    SET_FEATURED_PRODUCTS(products) {
      this.featured = products
    },
    SET_MULTIFUNCTIONAL_PRINTERS_LOADING(value) {
      this.loadingMultifunctionalPrinters = value
    },
    SET_PRODUCTION_PRINTERS_LOADING(value) {
      this.loadingProductionPrinters = value
    },
    SET_PLOTTER_PRINTERS_LOADING(value) {
      this.loadingPlotterPrinters = value
    },
    SET_FEATURED_PRINTERS_LOADING(value) {
      this.loadingFeaturedPrinters = value
    },
    SET_OFFICE_PRINTERS_LOADING(value) {
      this.loadingOfficePrinters = value
    },
    SET_PRODUCT_META(content) {
      const { slug, data } = content
      const pageMeta = {
        title: data.meta.title || '',
        description: data.meta.meta.description || '',
        card: data.meta.meta.card || '',
        url: process.SITE_DOMAIN + data.meta.meta.url || '',
        image: data.meta.meta.image || '',
        ogType: data.meta.meta.ogType || ''
      }

      this.productMeta[slug] = pageMeta
    }
  },

  // getters
  getters: {
    getProductPageContent: state => state.selected,
    viewProducts: state => state.multifunctionals.concat(state.office),
    viewMultifunctionalPrinters: state => state.multifunctionals,
    viewProductionPrinters: state => state.production,
    viewPlotterPrinters: state => state.plotter,
    viewScanners: state => state.allScanners,
    viewOfficePrinters: state => state.printOnly,
    viewSingleProduct: state => state.selected.content,
    featuredProducts: state => state.featured,
    getProductMeta: state => state.selected.meta,
    isProductLoaded: (state, getters) => {
      if (getters.isProductDataLoading) {
        return false
      } else {
        return true
      }
    }
  }
})
