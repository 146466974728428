import { createWebHistory, createMemoryHistory, createRouter as _createRouter } from 'vue-router'


const printerTypeRegex = '(multifunctionals|print-only|production|plotter)'
const scannerTypeRegex = '(personal|desktop-workgroup|departmental|production|all)'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      fetchPage: true
    }
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import('../views/Contact.vue'),
    meta: {
      fetchPage: true
    }
  },
  {
    path: '/customer-support',
    name: 'CustomerSupport',
    component: () => import('../views/Support.vue'),
    meta: {
      fetchPage: true
    }
  },
  {
    path: `/printers/:type${printerTypeRegex}/:brand/:model`,
    name: 'PrinterProduct',
    component: () => import('../views/Product.vue'),
    meta: {
      fetchProduct: true
    },
    validate: (params) => {
      const validTypes = ['multifunctionals', 'print-only', 'production', 'plotter']
      return validTypes.includes(params.type)
    }
  },
  {
    path: `/scanners/:type${scannerTypeRegex}/:brand/:model`,
    name: 'ScannerProduct',
    component: () => import('../views/Product.vue'),
    meta: {
      fetchProduct: true,
      fetchScanners: true
    },
    validate: (params) => {
      const validTypes = ['personal', 'desktop-workgroup', 'departmental', 'production', 'all']
      return validTypes.includes(params.type)
    }
  },
  {
    path: `/printers/:type${printerTypeRegex}`,
    name: 'PrinterList',
    // props: route => ({ page: route.query.page }),
    component: () => import('../views/ProductList.vue'),
    meta: {
      fetchPage: true,
      fetchProductList: true
    }
  },
  {
    path: `/scanners/:type${scannerTypeRegex}`,
    name: 'ScannerList',
    // props: route => ({ page: route.query.page }),
    component: () => import('../views/ProductList.vue'),
    meta: {
      fetchPage: true,
      fetchProductList: true,
      fetchScanners: true
    }
  },
  {
    path: `/printers/:type${printerTypeRegex}/:printerCategory`,
    name: 'PrinterCategoryList',
    // props: route => ({ page: route.query.page }),
    component: () => import('../views/ProductList.vue'),
    meta: {
      fetchPage: true,
      fetchProductList: true
    }
  },
  {
    path: '/discover-print-savings',
    name: 'ServiceChecker',
    component: () => import('../views/ServiceChecker.vue'),
    meta: {
      fetchPage: true
    }
  },
  {
    path: '/services/:slug',
    name: 'service-page',
    component: () => import('../views/Service.vue'),
    meta: {
      fetchPage: true
    }
  },
  {
    path: '/checkout-complete',
    name: 'CheckoutComplete',
    component: () => import('../views/CheckoutResult.vue'),
    meta: {
      fetchPage: true
    }
  },
  // Landing pages below, these are not part of the main navigation
  // Probably need a longer term solution, but we can hardcode the first one.
  {
    path: '/photocopiers',
    name: 'PhotocopiersLandingpage',
    component: () => import('../views/landing-pages/ProductKeyword.vue'),
    meta: {
      landingPage: true,
      fetchPage: true,
      fetchProductList: true,
    }
  }
]


export const createRouter = () =>
  _createRouter({
    routes,
    history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
      // Check if we are in a browser environment
      if (typeof window === 'undefined') {
        return
      }

      if (savedPosition && savedPosition.left !== 0 && savedPosition.top !== 0) {
        // savedPosition is only available for popstate navigations.
        return savedPosition
      } else {
        const position = {}
        // scroll to anchor by returning the selector
        if (to.hash) {
          position.selector = to.hash
          if (document.querySelector(to.hash)) {
            return { el: to.hash }
          }

          // if the returned position is falsy or an empty object,
          // will retain current scroll position.
          return false
        } else {
          return { top: 0 }
        }
      }
    }
  })