const state = {
  inUse: false,
  modelName: '',
  speed: {
    min: 0,
    max: 150
  },
  brand: 'all'
}

const actions = {
  SET_SPEED: ({ commit }, { target, value }) => {
    if (target === 'min') {
      commit('SET_SPEED_MIN', value)
    } else {
      commit('SET_SPEED_MAX', value)
    }
  },
  SET_MAX_SPEED: ({ commit }, value) => {
    commit('SET_SPEED_MAX', value)
  },
  SET_FILTER_MODEL_NAME: ({ commit }, value) => {
    commit('SET_FILTERMODELNAME', value)
  },
  SET_FILTER_BRAND: ({ commit }, value) => {
    commit('SET_FILTERBRAND', value)
  }
}

const mutations = {
  SET_SPEED_MIN: (state, value) => {
    state.speed.min = value
  },
  SET_SPEED_MAX: (state, value) => {
    state.speed.max = value
  },
  SET_FILTERMODELNAME: (state, value) => {
    state.modelName = value
  },
  SET_FILTERBRAND: (state, value) => {
    state.brand = value
  }
}

const getters = {
  getFilterState: state => state,
  getFilterSpeed: state => state.speed
}

export default { state, mutations, actions, getters }
